<div id="container">
    <h1>charles|maher</h1>
    <span>
        <h2>there is nothing here</h2>
        <p>(yet)</p>
    </span>
    <p>made with SvelteKit</p>
</div>

<style>
    :global(html, body) {
        margin: 0;
    }

    :root {
        font-family: sans-serif;
        color: white;
        background: black;
        text-align: center;
    }

    #container { 
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;
    }

    p {
        color: darkcyan;
    }
</style>
